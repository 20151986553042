import { nextTick } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import PChat from '../pages/p-chat'
import PData from '../pages/p-data'

import {
  CLIENT_NAME,
  CLIENT_NAMES,
} from '../constants'

const routes = [
  {
    path: '/',
    name: 'chat',
    component: PChat,
  },
  {
    path: '/data',
    name: 'data',
    component: PData,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.afterEach(() => {
  nextTick(() => {
    if (CLIENT_NAME === CLIENT_NAMES.CFO) {
      document.title = 'Datrics | CFO Co-Pilot';
    } else if (CLIENT_NAME === CLIENT_NAMES.CFO_TBI) {
      document.title = 'Datrics | CFO Co-Pilot';
    } else if (CLIENT_NAME === CLIENT_NAMES.LEGAL_NODES) {
      document.title = 'Datrics | Legal Co-Pilot';
    }
  });
});

export default router
