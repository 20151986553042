export const CLIENT_NAME = process.env.VUE_APP_CLIENT_NAME
export const SUBST_BOT_NAME = process.env.VUE_APP_SUBST_BOT_NAME || CLIENT_NAME
export const SHOW_SWITCH = process.env.VUE_APP_SHOW_SWITCH === 'true'

if (!CLIENT_NAME) {
  throw Error('Provide VUE_APP_CLIENT_NAME')
}
export const CLIENT_NAMES = {
  CFO: 'CFO',
  CFO_TBI: 'CFO_TBI',
  LEGAL_NODES: 'LegalNodes',
}

export const suggestedQuestionsCfo = [
  "What is the number of monthly applications in Q1 2023?",
  "What is the number of monthly applications in Q1 2023? Add plot",
  "What's the dynamic of new applications for lppl ecomm in Q3 2022?",
  "What is the trend or approved applications for 2022? Please forecast monthly approved applications in Q1 2023 based on this info and compare with actual numbers."
]
export const suggestedQuestionsLegalNodes = [
  "Tax implications for company in Estonia",
  "Company wants to organize CEX service in Brazil",
  "Check tax situation if the Legal entity from Portugal and the target country is Singapore?",
  "Check tax situation for individual resident from Ukraine who wants incorporate company in UK?",
]
const suggestedQuestionsByClient = {
  [CLIENT_NAMES.CFO]: suggestedQuestionsCfo,
  [CLIENT_NAMES.CFO_TBI]: suggestedQuestionsCfo,
  [CLIENT_NAMES.LEGAL_NODES]: suggestedQuestionsLegalNodes,
}

export const suggestedQuestions = suggestedQuestionsByClient[CLIENT_NAME] || []
