<template>
  <div class="o-body">
    <div class="o-body__wrapper">
      <OBodyMessages id="messages">
        <OMessage
          v-for="(message, index) in messages"
          :message="message"
          :key="index"
          @onSetMessage="onSetMessage"
        />
      </OBodyMessages>
      <div class="o-body__input-container">
        <AInput
          v-model="prompt"
          :disabled="isLoading"
          @onEnter="onClickSend"
        />
        <AButtonSend
          v-if="!isLoading"
          @click="onClickSend"
          :disabled="isLoading || !prompt"
        />
        <ProgressSpinner
          v-else
          style="width: 70px;
          height: 70px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".7s"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch, defineProps, onBeforeMount, computed, onMounted } from 'vue';
import * as uuid from 'uuid';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import AInput from '../a-input/a-input.vue';
import OMessage from '../o-message'
import OBodyMessages from './o-body-messages'
import AButtonSend from '../a-button-send/a-button-send.vue';

import { BACKEND_URL } from '../../api/backend-client';
import {
  generateCharts,
  getConversation,
  createConversation,
} from '../../api/conversation.api';
import { useScroll } from './use-scroll';

import {
  CLIENT_NAME,
  CLIENT_NAMES,
  SUBST_BOT_NAME,
  suggestedQuestions,
  SHOW_SWITCH,
} from '../../constants'

const props = defineProps({
  conversation: {
    type: Object,
    default: () => null,
  },
})

const {
  scrollDetect,
  scrollToBottom,
} = useScroll()

const store = useStore()
const route = useRoute()
const userUuid = ref(null)
const prompt = ref('')
const messages = ref([])
const isLoading = ref(false)
const conversationUuid = ref(null)
const routeConversationUuid = computed(() => route.query.conversationUuid)
const messageUuid = ref(null)
userUuid.value = localStorage.getItem('userUuid')

const getUseCache = () => localStorage.getItem('useCache') === 'true' || SHOW_SWITCH === false

const generateInitialMessage = () => {
  const message = {
    uuid: uuid.v4(),
    type: 'system',
    title: `<b>${SUBST_BOT_NAME} copilot, how can I help you?</b>`,
    isLoading: false,
    questions: suggestedQuestions,
  }
  return message
}

const getMessageCharts = async (message_uuid) => {
  const { data } = await generateCharts({
    uuid: message_uuid,
    user_uuid: userUuid.value,
    conversation_uuid: routeConversationUuid.value || conversationUuid.value,
  })
  return data.charts
}

const updateMessagesCharts = () => {
  messages.value.forEach(async (message) => {
    const charts = await getMessageCharts(message.uuid)
    message.charts = charts
  })
}

const handleGetConversation = async ({ conversation_uuid }) => {
  if (!conversation_uuid) return

  const { data } = await getConversation({
      uuid: conversation_uuid,
      user_uuid: userUuid.value,
  })
  messages.value = data.messages.map((message) => {
    let textTechDetails = ''
    let textSummary = ''
    if (message.answer) {
      const text = message.answer.replace(/<div class="tech-details-start"\/>/g, '')
      const textParts = text.split('<div class="tech-details-end"/>')
      if (textParts.length > 1) {
        textTechDetails = textParts[0]
        textSummary = textParts[1]
      } else {
        textSummary = textParts[0]
      }
    }
    return {
      ...message,
      type: message.role,
      prompt: message.prompt,
      textTechDetails,
      textSummary,
      isLoading: false,
      charts: [],
    }
  })

  updateMessagesCharts()
  if (data.messages.length === 0) {
    messages.value.push(generateInitialMessage())
  }
}

watch(() => props.conversation, (val1, val2) => {
  console.log(props.conversation)
  if (val1 === val2) return
  handleGetConversation({ conversation_uuid: props.conversation?.uuid })
  console.log(props.conversation)
})

messages.value.push(generateInitialMessage())

const initMessage = () => reactive({
  type: null,
  title: '',
  text: '',
  textTechDetails: '',
  textSummary: '',
  isTextTechDetails: true,
  prompt: '',
  query: '',
  data: null,
  summary: '',
  charts: null,
  isLoading: false,
  isLoadingCharts: false,
  error: null,
})

let currentMessage = initMessage()

const handleEventSourceWithBody = async (messageKey, endpoint) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conversation: {
        uuid: routeConversationUuid.value || conversationUuid.value,
        user_uuid: userUuid.value,
      },
      message: {
        uuid: messageUuid.value,
        user_uuid: userUuid.value,
        conversation_uuid: routeConversationUuid.value || conversationUuid.value,
        prompt: String(prompt.value),
        use_cache: getUseCache(),
      }
    })
  })

     // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let { value, done } = await reader.read();
    if (done) break;
    if (value?.includes('<div class="tech-details-start"/>')) {
      currentMessage.isTextTechDetails = true
    }
    if (value?.includes('<div class="tech-details-end"/>')) {
      currentMessage.isTextTechDetails = false
    }
    if (value && currentMessage.isTextTechDetails && value !== '<div class="tech-details-start"/>') {
      currentMessage.textTechDetails += `${value}`
    }
    if (value && !currentMessage.isTextTechDetails && value !== '<div class="tech-details-end"/>') {
      currentMessage.textSummary += `${value}`
    }
    scrollToBottom('messages')
    console.log('Received', value);
  }
}

const handleGetStreamAnswer = async () => {
  await handleEventSourceWithBody('text', `${BACKEND_URL}/conversation/stream`)
}

const handleCreateConversation = async () => {
  const { data } = await createConversation({
    conversation: {
      uuid: routeConversationUuid.value || conversationUuid.value,
      user_uuid: userUuid.value,
    },
    message: {
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
      prompt: String(prompt.value),
    }
  })
  return data.charts
}

const handleCfo = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    store.dispatch('conversations/getConversations')
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    currentMessage.isLoadingCharts = true
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    const resCharts = await generateCharts({
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
    })
    currentMessage.charts = resCharts.data.charts
    scrollToBottom('messages')
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const handleCfoTbi = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    store.dispatch('conversations/getConversations')
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    currentMessage.isLoadingCharts = true
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    const resCharts = await generateCharts({
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
    })
    currentMessage.charts = resCharts.data.charts
    scrollToBottom('messages')
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const handleLegalNodes = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    store.dispatch('conversations/getConversations')
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const clientFlowHandlers = {
  [CLIENT_NAMES.CFO]: handleCfo,
  [CLIENT_NAMES.CFO_TBI]: handleCfoTbi,
  [CLIENT_NAMES.LEGAL_NODES]: handleLegalNodes,
}

const onSetMessage = (message) => {
  prompt.value = message
}

const onClickSend = async () => {
  isLoading.value = true
  if (!conversationUuid.value && !routeConversationUuid.value) {
    conversationUuid.value = uuid.v4()
  }
  messageUuid.value = uuid.v4()
  messages.value.push({
    type: 'user',
    prompt: prompt.value,
  })
  currentMessage = initMessage()
  messages.value.push(currentMessage)

  try {
    await clientFlowHandlers[CLIENT_NAME]()
  } catch (e) {
    console.log(e)
  } finally {
    prompt.value = null
    isLoading.value = false
    currentMessage.isLoading = false
    currentMessage.isLoadingCharts = false
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
  }
}

onBeforeMount(async () => {
  const route = useRoute()
  const { conversationUuid: cUuid } = route.query
  conversationUuid.value = cUuid
  await handleGetConversation({ conversation_uuid: conversationUuid.value })
})
onMounted(() => {
  scrollDetect('messages')
})
</script>

<style lang="scss">
@import "o-body.scss";
</style>

// Compare this year/quarter's net income (NI) with the same period of the previous year. Find factors causing changes.