<template>
  <input
    v-model="inputValue"
    type="text"
    class="a-input"
    :disabled="disabled"
    @keyup.enter="onEnter"
  >
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: () => '',
  },
  disabled: {
    type: Boolean,
    default: () => false,
  }
})
const emit = defineEmits(['update:modelValue', 'onEnter'])
const inputValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const onEnter = () => {
  emit('onEnter')
}
</script>

<style lang="scss">
@import "a-input.scss";
</style>