<template>
  <div class="o-header">
    <div class="o-header__left">
      <AIconDatricsFull v-if="CLIENT_NAMES.CFO === CLIENT_NAME" class="o-header__logo" />
      <AIconDatricsFull v-if="CLIENT_NAMES.CFO_TBI === CLIENT_NAME" class="o-header__logo" />
      <AIconLegalnodesFull v-if="CLIENT_NAMES.LEGAL_NODES === CLIENT_NAME" class="o-header__logo" />
      <div class="o-header__tabs">
        <OHeaderTab
          v-for="item in clientTabs[CLIENT_NAME]"
          :tab="item"
          :key="item.title"
        />
      </div>
    </div>
    <div class="o-header__right" v-if="SHOW_SWITCH">
      <InputSwitch
        v-model="useCache"
        @input="onUseCacheChange"
      />
      <span class="o-header__cache">Use cache</span>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import InputSwitch from 'primevue/inputswitch';
import AIconDatricsFull from '../a-icon-datrics-full'
import AIconLegalnodesFull from '../a-icon-legalnodes-full'
import OHeaderTab from './o-header-tab'

import {
  CLIENT_NAME,
  CLIENT_NAMES,
  SHOW_SWITCH,
} from '../../constants'
import { ref } from 'vue';

const TABS = {
  CHAT: { title: 'Chat', pathName: 'chat' },
  DATA: { title: 'Data', pathName: 'data' },
}

const clientCfoTabs = [
  TABS.CHAT,
  // TABS.DATA,
]
const clientCfoTbiTabs = [
  TABS.CHAT,
  // TABS.DATA,
]
const clientLegalNodesTabs = [
  TABS.CHAT,
]

const clientTabs = {
  [CLIENT_NAMES.CFO]: clientCfoTabs,
  [CLIENT_NAMES.CFO_TBI]: clientCfoTbiTabs,
  [CLIENT_NAMES.LEGAL_NODES]: clientLegalNodesTabs,
}

const store = useStore()

const useCache = ref(store.state.config.useCache)

const onUseCacheChange = (value) => {
  console.log('onUseCacheChange', value)
  store.dispatch('config/setUseCache', value)
}


</script>

<style lang="scss">
@import "o-header.scss";
</style>