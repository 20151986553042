import backendClient from './backend-client'

const ENDPOINTS = {
  GENERATE_QUERY: `/generate-query`,
  GENERATE_DATA: `/generate-data`,
  GENERATE_SUMMARY: `/generate-summary`,
  GENERATE_CHARTS: `/generate-charts`,
  GET_CONVERSATIONS: `/conversations/list`,
  GET_CONVERSATION: `/conversation/item`,
  CREATE_CONVERSATION: `/conversation/create`,
  STREAM_CONVERSATION: `/conversation/stream`,
}

export const generateQuery = ({ prompt }) => (
  backendClient.post(ENDPOINTS.GENERATE_QUERY, { prompt }, {
    timeout: 300000,
    responseType: 'stream',
  })
)
export const generateData = (payload) => (
  backendClient.post(ENDPOINTS.GENERATE_DATA, payload)
)
export const generateSummary = ({ prompt }) => (
  backendClient.post(ENDPOINTS.GENERATE_SUMMARY, { prompt }, {
    timeout: 300000,
  })
)
export const generateCharts = (payload) => (
  backendClient.post(ENDPOINTS.GENERATE_CHARTS, payload)
)
export const createConversation = (payload) => (
  backendClient.post(ENDPOINTS.CREATE_CONVERSATION, payload)
)
export const getConversations = (payload) => (
  backendClient.post(ENDPOINTS.GET_CONVERSATIONS, payload)
)
export const getConversation = (payload) => (
  backendClient.post(ENDPOINTS.GET_CONVERSATION, payload)
)
