<template>
  <div class="o-sidebar">
    <div class="sidebar__header">My questions</div>
    <div class="sidebar__chats">
      <AConversationItem
        v-for="item in conversations"
        :key="item.uuid"
        @click="onClickItem(item)"
      >
        {{ item.title }}
      </AConversationItem>
    </div>

    <div class="sidebar__button-new-chat">
      <AButtonNewChat
        @click="onClickNewChat"
      />
    </div>
  </div>
</template>

<script setup>
import * as uuid from 'uuid'
import { useStore } from 'vuex'
import { defineEmits, computed } from 'vue'
import router from '../../router'

import {AButtonNewChat} from '../a-button-new-chat'
import AConversationItem from './a-conversation-item'

const store = useStore()
const conversations = computed(() => store.state.conversations.conversations)

const emit = defineEmits(['onChangeConversation'])
const onClickItem = (item) => {
  router.push({ name: 'chat', query: { conversationUuid: item.uuid } })
  emit('onChangeConversation', item)
}
const onClickNewChat = () => {
  onClickItem({ uuid: uuid.v4() })
}

</script>

<style lang="scss">
@import "o-sidebar.scss";
</style>
