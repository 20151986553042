<template>
  <div class="p-data">
    <iframe
      :src="iframeUrl[CLIENT_NAME]"
      frameborder="0"
      width="100%"
      allowtransparency
    ></iframe>
  </div>
</template>

<script setup>
import { CLIENT_NAMES, CLIENT_NAME } from '../../constants';

const iframeUrl = {
  [CLIENT_NAMES.CFO]: 'https://metabase.app.datrics.ai/public/dashboard/23340225-88a0-4f7d-87e8-cf0fb0d93f22',
  [CLIENT_NAMES.CFO_TBI]: 'https://metabase.app.datrics.ai/public/dashboard/9bc3114b-2baf-4aa8-89a3-00ebc9ad69b2',
}
</script>

<style lang="scss">
.p-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

iframe {
  height: calc(100vh - 100px);
  border-radius: 20px;
}
</style>