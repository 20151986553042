<template>
  <div class="container-body">
    <OSidebar
      v-if="true"
      @onChangeConversation="onChangeConversation"
    />
    <OChatBody
      :conversation="selectedConversation"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex'

import OSidebar from '../../components/o-sidebar'
import OChatBody from '../../components/o-body'

const store = useStore()
const userUuid = ref(null)
const selectedConversation = ref(null)

onBeforeMount(async () => {
  userUuid.value = localStorage.getItem('userUuid')
  if (!userUuid.value) {
    store.dispatch('user/createUserUuid')
  }
  store.dispatch('conversations/getConversations')
})

const onChangeConversation = (item) => {
  selectedConversation.value = item
  console.log(selectedConversation.value)
}

</script>

<style lang="scss">
.container-body {
  height: calc(100% - 70px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 20px 30px;
  box-sizing: border-box;
}
</style>