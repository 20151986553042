import { getConversations } from '../api/conversation.api'

const conversations = {
  namespaced: true,
  state: () => ({
    conversations: [],
    currentConversation: null,
  }),
  mutations: {
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations
    },
    SET_CURRENT_CONVERSATION(state, conversation) {
      state.currentConversation = conversation
    },
  },
  actions: {
    async getConversations({ commit, rootGetters }) {
      const userUuid = rootGetters['user/getUserUuid']
      const { data } = await getConversations({
        uuid: userUuid,
      })
      commit('SET_CONVERSATIONS', data)
    }
  },
  getters: {
    getConversations: (state) => {
      return state.conversations || []
    },
  },
}

export default conversations