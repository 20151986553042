<template>
  <!-- <div
    v-if="message.isLoading"
    class="o-message"
  >
    <div class="o-message__header">
      <div class="o-message__header--left">
        <Skeleton shape="circle" size="1.5rem" class="mr-2"></Skeleton>
      </div>
      <div class="o-message__header--right">
        <Skeleton class="mb-2" borderRadius="16px" width="250px"></Skeleton>
      </div>
    </div>
    <div class="o-message__body">
      <Skeleton class="mb-2" borderRadius="16px" width="200px"></Skeleton>
      <Skeleton
        shape="rounded"
        width="100%"
        height="4rem"
      />
      <Skeleton class="mb-2" borderRadius="16px" width="300px"></Skeleton>
    </div>
  </div> -->
  <div
    class="o-message"
    :class="messageClass"
  >
    <div class="o-message__header">
      <div class="o-message__header--left">
        <AIconDatrics v-if="!isUserMessage && CLIENT_NAMES.CFO === CLIENT_NAME" />
        <AIconDatrics v-else-if="!isUserMessage && CLIENT_NAMES.CFO_TBI === CLIENT_NAME" />
        <AIconLegalnodesFull v-else-if="!isUserMessage && CLIENT_NAMES.LEGAL_NODES === CLIENT_NAME" class="o-message__header-icon" />
        <AIconUser v-else />
      </div>
      <div class="o-message__header--right">
        <div v-if="message.title" v-html="message.title"></div>
        <div v-if="message.prompt" v-html="message.prompt"></div>
        <div v-if="message.questions && message.questions.length">
          <AButtonMessage
            v-for="(question, index) in message.questions"
            :text="question"
            :key="index"
            @click="onSetMessage(question)"
          >{{ question }}</AButtonMessage>
        </div>

        <Accordion
          v-if="message.textTechDetails"
          
        >
          <AccordionTab>
            <template #header>
              <div class="p-d-flex p-ai-center">
                <span class="p-mr-2">Tech details</span>
                <span style="margin-right: 20px;"></span>
                <ProgressSpinner 
                  v-if="!message.textSummary && message.isLoading"
                  style="width: 20px; height: 20px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
              </div>
            </template>
            <p>
              <v-md-editor v-if="message.textTechDetails" v-model="textTechDetails" mode="preview" height="auto" class="md-editor"></v-md-editor>
            </p>
          </AccordionTab>
        </Accordion>
        <div v-if="message.textSummary && message.textSummary.length > 1">
          <v-md-editor v-if="message.textSummary" v-model="textSummary" mode="preview" height="auto" class="md-editor"></v-md-editor>
        </div>
        <OCodeEditor
          v-if="message.query"
          :model-value="message.query"
        />
        <ODataTable
          v-if="message.data"
          :items="message.data"
        ></ODataTable>
        <div v-if="message.summary" v-html="message.summary"></div>
        <AProgressBar v-if="message.isLoadingCharts && !message.charts"/>
        <div
          v-if="message.charts"
          class="o-message__charts"
        >
          <iframe
            v-for="(chart, index) in message.charts"
            :srcdoc="chart"
            width="100%"
            style="height: 500px; border: none; padding: 0; margin: 0;"
            :key="index"
          />
          <!-- <img
            v-for="(chart, index) in message.charts"
            :src="`data:image/png;base64,${chart}`"
            :key="index"
            :alt="index"
          > -->
        </div>
        <div v-if="message.error" v-html="message.error"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';

import AIconDatrics from '../a-icon-datrics'
import AIconUser from '../a-icon-user';
import ODataTable from '../o-data-table/o-data-table.vue';
import OCodeEditor from '../o-code-editor/o-code-editor.vue';
import AProgressBar from '../a-progress-bar/a-progress-bar.vue';
import AIconLegalnodesFull from '../a-icon-legalnodes-full'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import {
  CLIENT_NAME,
  CLIENT_NAMES,
} from '../../constants'
import { AButtonMessage } from '../a-button-message';

const emit = defineEmits(['onSetMessage'])
const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  },
})

const textTechDetails = computed({
  get: () => props.message.textTechDetails,
})
const textSummary = computed({
  get: () => props.message.textSummary,
})

const isUserMessage = computed(() => props.message.type === 'user')

const messageClass = computed(() => ({
  'o-message--highlighted': isUserMessage.value,
}))

const onSetMessage = (text) => {
  emit('onSetMessage', text)
}
console.log(props.message)
</script>

<style lang="scss">
@import "o-message.scss";
</style>
